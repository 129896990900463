import getSalespageCountdownFlagLoader from "@loaders/getSalespageFlagLoader"
import * as Sentry from "@sentry/react"
import { AuthError, createClient, Session, User } from "@supabase/supabase-js"
import Cookies from "js-cookie"
import {
  CancelRevenueCatSubscriptionsData,
  EdgeFunctionError,
  EventName,
  GetStripePaymentMethodTypeResponse,
  RefundRevenueCatSubscriptionsData,
  StripeCoupon,
  StripePrice,
} from "src/types"
import { getIsFemaleFromLocalStorage } from "./localStorage"
import { getUserId } from "./sessionStorage"

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL as string
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY as string

const supabase = createClient(supabaseUrl, supabaseKey)

export async function cancelRevenueCatSubscriptions(): Promise<CancelRevenueCatSubscriptionsData> {
  const response = await supabase.functions.invoke(
    "cancel-revenuecat-subscriptions",
  )

  const data = response.data as CancelRevenueCatSubscriptionsData
  const error = response.error as EdgeFunctionError

  // eslint-disable-next-line @typescript-eslint/only-throw-error
  if (error) throw error

  return data
}

export async function createStripeSubscription({
  couponId,
  currency,
  email,
  priceId,
}: {
  couponId: string
  currency: string
  email: string | undefined
  priceId: string
}) {
  const response = await supabase.functions.invoke(
    "create-stripe-subscription",
    {
      body: {
        ...(couponId && { couponId }),
        currency,
        email,
        priceId,
      },
    },
  )

  const data = response.data as {
    clientSecret: string
    subscriptionId: string
  }

  const error = response.error as EdgeFunctionError

  // eslint-disable-next-line @typescript-eslint/only-throw-error
  if (error) throw error

  return { data }
}

export async function getUser(): Promise<{
  data: { user: User | null }
  error: AuthError | null
}> {
  const { data, error } = await supabase.auth.getUser()

  return { data, error }
}

export async function getSession(): Promise<{
  data: { session: Session | null }
  error: AuthError | null
}> {
  const { data, error } = await supabase.auth.getSession()

  return { data, error }
}

export async function getStripePaymentMethodType(
  paymentMethodId: string,
): Promise<GetStripePaymentMethodTypeResponse> {
  const response = await supabase.functions.invoke(
    "get-stripe-payment-method-type",
    {
      body: {
        paymentMethodId,
      },
    },
  )

  const data = response.data as GetStripePaymentMethodTypeResponse
  const error = response.error as EdgeFunctionError

  if (error) {
    console.error("error", error)
  }

  return data
}

export async function getFxRate(currency: string): Promise<number | null> {
  const { error, data } = await supabase
    .from("fx_rates")
    .select("*")
    .order("created_at", { ascending: false })
    .limit(1)

  if (error) {
    console.error("error", error, data)
    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return (data[0][currency.toLowerCase()] as number) ?? null
}

export async function listStripeCoupons(): Promise<StripeCoupon[]> {
  const response = await supabase.functions.invoke("list-stripe-coupons")

  const data = response.data as StripeCoupon[]
  const error = response.error as EdgeFunctionError

  // eslint-disable-next-line @typescript-eslint/only-throw-error
  if (error) throw error

  return data
}

export async function listStripePrices(): Promise<StripePrice[]> {
  const response = await supabase.functions.invoke("list-stripe-prices", {
    body: {
      productId: import.meta.env.VITE_STRIPE_PRODUCT_ID as string,
    },
  })

  const data = response.data as StripePrice[]
  const error = response.error as EdgeFunctionError

  // eslint-disable-next-line @typescript-eslint/only-throw-error
  if (error) throw error

  return data
}

export async function refundRevenueCatSubscriptions(): Promise<RefundRevenueCatSubscriptionsData> {
  const response = await supabase.functions.invoke(
    "refund-revenuecat-subscriptions",
  )

  const data = response.data as RefundRevenueCatSubscriptionsData
  const error = response.error as EdgeFunctionError

  // eslint-disable-next-line @typescript-eslint/only-throw-error
  if (error) throw error

  return data
}

export async function resetPasswordForEmail({ email }: { email: string }) {
  const { error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: `${window.location.origin}/account/reset-password`,
  })

  if (error) {
    console.error("error", error)
  }

  return { error }
}

export async function trackEvent({
  currency,
  eventId,
  eventName,
  eventSourceUrl,
  fbc,
  fbcFromCookie,
  fbcFromLocalStorage,
  fbp,
  pixelId,
  userCity,
  userCountryCode,
  userEmail,
  userId,
  userZipCode,
  value,
}: {
  currency?: string
  eventId: string
  eventName: EventName
  eventSourceUrl: string
  fbc: string | undefined | null
  fbcFromCookie: string | undefined | null
  fbcFromLocalStorage: string | undefined | null
  fbp: string | undefined
  pixelId: string
  userCity: string | undefined
  userCountryCode: string | undefined
  userEmail: string | undefined
  userId: string | undefined
  userZipCode: string | undefined
  value?: number
}) {
  const response = await supabase.functions.invoke("track-event", {
    body: {
      currency,
      eventId,
      eventName,
      eventSourceUrl,
      fbc,
      fbcFromCookie,
      fbcFromLocalStorage,
      fbp,
      pixelId,
      userCity,
      userCountryCode,
      userEmail,
      userId,
      userZipCode,
      value,
    },
  })

  const error = response.error as EdgeFunctionError

  if (error) {
    console.error(error)
  }
}

export async function signInWithPassword({
  email,
  password,
}: {
  email: string
  password: string
}) {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  })

  if (error) Sentry.captureException(error)

  return { data, error }
}

export async function signUp({
  email,
  password,
}: {
  email: string
  password: string
}) {
  const flagValue = await getSalespageCountdownFlagLoader()
  const confidenceVisitorId = Cookies.get("cnfdVisitorId")

  console.log(flagValue)

  const options = {
    data: {
      confidence_visitor_id: confidenceVisitorId ?? "unknown",
      sign_up_platform: "web",
    },
  }

  const { error } = await supabase.auth.signUp({
    email,
    password,
    options: options,
  })

  if (error) {
    console.error("error", error)
  }

  return { error }
}

export async function updatePassword({ password }: { password: string }) {
  const { error } = await supabase.auth.updateUser({
    password,
  })

  if (error) {
    console.error("error", error)
  }

  return { error }
}

export async function storeGender() {
  const userId = await getUserId()

  const { error } = await supabase.from("profiles").insert({
    is_female: getIsFemaleFromLocalStorage(),
    user_id: userId,
  })

  if (error) {
    console.error("error", error)
  }
}
