import navigateToNextOnboardingPageAction from "@actions/navigateToNextOnboardingPageAction"
import skipOnboardingLoader from "@loaders/skipOnboardingLoader"
import BestLookPage from "@pages/onboarding/BestLookPage"
import ComfortPage from "@pages/onboarding/ComfortPage"
import CompanyPage from "@pages/onboarding/CompanyPage"
import CurrentStylePage from "@pages/onboarding/CurrentStylePage"
import GenderPage from "@pages/onboarding/GenderPage"
import PersonalityPage from "@pages/onboarding/PersonalityPage"
import SharePhotoPage from "@pages/onboarding/SharePhotoPage"
import TryFirstPage from "@pages/onboarding/TryFirstPage"
import v1OnboardingRoutes from "@pages/onboarding/v1/v1OnboardingRoutes"
import v2OnboardingRoutes from "@pages/onboarding/v2/v2OnboardingRoutes"
import v3OnboardingRoutes from "@pages/onboarding/v3/v3OnboardingRoutes"
import v4OnboardingRoutes from "@pages/onboarding/v4/v4OnboardingRoutes"
import { Navigate, Outlet } from "react-router-dom"

const onboardingRoutes = [
  {
    path: "/onboarding",
    element: <Outlet />,
    loader: skipOnboardingLoader,
    children: [
      {
        element: <Navigate to="gender" replace />,
        index: true,
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <GenderPage />,
        path: "gender",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TryFirstPage />,
        path: "tryFirst",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <BestLookPage />,
        path: "bestLook",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <CurrentStylePage />,
        path: "style",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <SharePhotoPage />,
        path: "sharePhoto",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ComfortPage />,
        path: "comfort",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <PersonalityPage />,
        path: "personality",
      },

      {
        action: navigateToNextOnboardingPageAction,
        element: <CompanyPage />,
        path: "company",
      },
      {
        children: v1OnboardingRoutes,
        element: <Outlet />,
        path: "v1",
      },
      {
        children: v2OnboardingRoutes,
        element: <Outlet />,
        path: "v2",
      },
      {
        children: v3OnboardingRoutes,
        element: <Outlet />,
        path: "v3",
      },
      {
        children: v4OnboardingRoutes,
        element: <Outlet />,
        path: "v4",
      },
    ],
  },
]

export default onboardingRoutes
